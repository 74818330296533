import ReplayIcon from '@mui/icons-material/Replay';
import {
  Button,
  Dialog,
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
} from '@mui/material';
import {
  format as dateFusFormat,
  isBefore,
  addMonths,
  addDays,
  getWeekOfMonth,
  startOfDay,
} from 'date-fns';
import { useState, forwardRef, useEffect } from 'react';

import { theme } from '../../theme';
import { formatDate } from '../../utils/format';

export interface DateRepeatSettingProps {
  endDate?: string;
  onChange?: (value: string[]) => void;
  startDate?: string;
  value?: string[];
}

export const DateRepeatSetting = forwardRef(function (
  { value, onChange, startDate, endDate }: DateRepeatSettingProps,
  ref
) {
  const startDateDt = startOfDay(new Date(startDate ?? ''));
  const endDateDt = endDate
    ? startOfDay(new Date(endDate))
    : addMonths(startDateDt, 6);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [repeatType, setRepeatType] = useState('');
  const [weeklyDayWeek, setWeeklyDayWeek] = useState(1);
  const [monthlyDayWeek, setMonthlyDayWeek] = useState(1);
  const [monthlyWeek, setMonthlyWeek] = useState(1);
  const [toDate, setToDate] = useState(dateFusFormat(endDateDt, 'yyyy-MM-dd'));
  const [toDateErrorMessage, setToDateErrorMessage] = useState('');
  useEffect(() => {
    if (endDate) {
      setToDate(dateFusFormat(new Date(endDate), 'yyyy-MM-dd'));
    } else {
      setToDate(dateFusFormat(new Date(endDateDt), 'yyyy-MM-dd'));
    }
  }, [endDate, endDateDt]);
  return (
    <>
      <Button
        ref={ref as React.RefObject<HTMLButtonElement>}
        variant="outlined"
        color="secondary"
        size="small"
        sx={{ ml: 'auto', width: '11rem' }}
        // disabled={!!value?.length}
        onClick={() => {
          setIsOpenDialog(true);
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ReplayIcon sx={{ color: theme.palette.grey[500] }} />
          繰り返しで設定
        </Box>
      </Button>
      <Dialog
        open={isOpenDialog}
        maxWidth={false}
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <Box display="flex" flexDirection="column" gap={4} width="27rem" p={5}>
          <Typography variant="h6">募集する来店日を繰り返しで設定</Typography>
          <Typography>{formatDate(startDateDt)}以降</Typography>
          <RadioGroup
            sx={{ gap: 1 }}
            value={repeatType}
            onChange={(e, value) => {
              setRepeatType(value);
            }}
          >
            <FormControlLabel
              sx={{ width: '5rem' }}
              value="everyDay"
              control={<Radio />}
              label="毎日"
            />
            <Box display="flex" alignItems="center" gap={1}>
              <FormControlLabel
                sx={{ width: '5rem' }}
                value="weekly"
                control={<Radio />}
                label="毎週"
              />
              <Select
                sx={{ width: '6rem' }}
                value={weeklyDayWeek}
                onChange={(e) => {
                  setWeeklyDayWeek(Number(e.target.value));
                }}
                disabled={repeatType !== 'weekly'}
              >
                {['日', '月', '火', '水', '木', '金', '土'].map((w, idx) => (
                  <MenuItem value={idx} key={idx}>
                    {w}曜日
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <FormControlLabel
                sx={{ width: '5rem' }}
                value="monthly"
                control={<Radio />}
                label="毎月"
              />
              <Select
                sx={{ width: '6rem' }}
                value={monthlyWeek}
                onChange={(e) => {
                  setMonthlyWeek(Number(e.target.value));
                }}
                disabled={repeatType !== 'monthly'}
              >
                {Array.from(Array(5).keys()).map((n) => (
                  <MenuItem value={n + 1} key={n + 1}>
                    第{n + 1}
                  </MenuItem>
                ))}
              </Select>
              <Select
                sx={{ width: '6rem' }}
                value={monthlyDayWeek}
                onChange={(e) => {
                  setMonthlyDayWeek(Number(e.target.value));
                }}
                disabled={repeatType !== 'monthly'}
              >
                {['日', '月', '火', '水', '木', '金', '土'].map((w, idx) => (
                  <MenuItem value={idx} key={idx}>
                    {w}曜日
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </RadioGroup>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography>終了日</Typography>
            <TextField
              type="date"
              value={toDate}
              onChange={(e) => {
                const targetDate = startOfDay(new Date(e.target.value));
                setToDateErrorMessage('');
                if (isBefore(endDateDt, targetDate)) {
                  setToDateErrorMessage(
                    `${formatDate(endDateDt)}より後は入力できません`
                  );
                }
                if (isBefore(targetDate, startDateDt)) {
                  setToDateErrorMessage(
                    `${formatDate(startDateDt)}より前は入力できません`
                  );
                }
                setToDate(e.target.value);
              }}
              error={!!toDateErrorMessage}
              helperText={toDateErrorMessage}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            width="100%"
            mt="auto"
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={{ ml: 'auto', width: '10rem' }}
              onClick={() => {
                setIsOpenDialog(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '10rem' }}
              onClick={() => {
                if (toDateErrorMessage) {
                  return;
                }
                const toDateDt = new Date(toDate);
                const dateCount = Math.ceil(
                  (toDateDt.getTime() - startDateDt.getTime()) /
                    (24 * 60 * 60 * 1000)
                );
                const dateList = Array.from(Array(dateCount).keys())
                  .map((n) => addDays(startDateDt, n))
                  .filter((date) => {
                    if (repeatType === 'everyDay') {
                      return true;
                    }
                    if (repeatType === 'weekly') {
                      if (date.getDay() === weeklyDayWeek) {
                        return true;
                      }
                    }
                    if (repeatType === 'monthly') {
                      const week = getWeekOfMonth(date);
                      const firstDayOfMonth = new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        1
                      );
                      const dayOfWeekFirstDayOfMonth = firstDayOfMonth.getDay();
                      const addMonthlyWeek =
                        dayOfWeekFirstDayOfMonth > monthlyDayWeek ? 1 : 0;
                      if (
                        week === monthlyWeek + addMonthlyWeek &&
                        date.getDay() === monthlyDayWeek
                      ) {
                        return true;
                      }
                    }
                    return false;
                  })
                  .map((d) => dateFusFormat(d, 'yyyy/MM/dd'));

                setIsOpenDialog(false);

                const margeDateList = Array.from(
                  new Set(
                    [...dateList, ...(value ?? [])].sort((a, b) => {
                      const da = new Date(a);
                      const db = new Date(b);
                      if (isBefore(da, db)) {
                        return -1;
                      }
                      if (isBefore(db, da)) {
                        return 1;
                      }
                      return 0;
                    })
                  )
                );
                onChange?.(margeDateList);
              }}
            >
              決定
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
});
