import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  Paper,
  MenuItem,
  Select,
  Grid,
  Divider,
  useTheme,
  styled,
  FormHelperText,
} from '@mui/material';
import { format as dateFusFormat, addMonths, subMonths } from 'date-fns';
import dateFnsJs from 'date-fns/locale/ja';
import { useState, forwardRef } from 'react';

import { DateRepeatSetting } from './DateRepeatSetting';
import { ProductCalendar } from './ProductCalendar';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const ChangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `solid 1px ${theme.palette.grey[400]}`,
  maxHeight: '2rem',
  maxWidth: '2rem',
  minHeight: '2rem',
  minWidth: '2rem',
}));

export interface ProductDateSettingProps {
  endDate?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (value: string[]) => void;
  startDate?: string;
  value?: string[];
}

export const ProductDateSetting = forwardRef(function (
  {
    value,
    onChange,
    error,
    helperText,
    endDate,
    startDate: propsStartDate,
  }: ProductDateSettingProps,
  ref
) {
  const today = new Date();
  const startDate = propsStartDate ?? dateFusFormat(today, 'yyyy/MM/dd');
  const [selectMonth, setSelectMonth] = useState<string>(
    dateFusFormat(today, 'yyyy/MM')
  );
  const [selectNextMonth, setSelectNextMonth] = useState<string>(
    dateFusFormat(addMonths(today, 1), 'yyyy/MM')
  );
  const monthCount = today.getDate() === 1 ? 5 : 6;
  const monthList = Array.from(Array(monthCount).keys()).map((i) => {
    return dateFusFormat(addMonths(today, i), 'yyyy/MM');
  });
  const nextMonthList = Array.from(Array(monthCount).keys()).map((i) => {
    return dateFusFormat(addMonths(today, i + 1), 'yyyy/MM');
  });
  const [isDaysViewAll, setIsDaysViewAll] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <ColBox>
      <Paper
        sx={{
          border: error ? `solid 1px ${theme.palette.error.main}` : undefined,
          height: 'fit-content',
          width: 'fit-content',
        }}
        variant="outlined"
      >
        <ColBox>
          <RowBox gap={4} p={2}>
            <ColBox>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                >
                  <ChangeButton
                    variant="outlined"
                    size="small"
                    disabled={monthList[0] === selectMonth}
                    onClick={(e) => {
                      const date = addMonths(new Date(`${selectMonth}/01`), -1);
                      setSelectMonth(dateFusFormat(date, 'yyyy/MM'));
                      setSelectNextMonth(
                        dateFusFormat(addMonths(date, 1), 'yyyy/MM')
                      );
                    }}
                  >
                    <ArrowBackIosNew fontSize="small" color="action" />
                  </ChangeButton>
                </Grid>
                <Grid
                  item
                  xs={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Select
                    sx={{ width: '10rem' }}
                    size="small"
                    value={selectMonth}
                    onChange={(e) => {
                      setSelectMonth(e.target.value);
                      setSelectNextMonth(
                        dateFusFormat(
                          addMonths(new Date(`${e.target.value}/01`), 1),
                          'yyyy/MM'
                        )
                      );
                    }}
                  >
                    {monthList.map((month) => (
                      <MenuItem key={month} value={month}>
                        {dateFusFormat(new Date(`${month}/01`), 'yyyy年MM月')}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <ProductCalendar
                month={selectMonth}
                startDate={startDate}
                endDate={endDate}
                value={value}
                onChange={onChange}
              />
            </ColBox>
            <ColBox>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                >
                  <Box></Box>
                </Grid>
                <Grid
                  item
                  xs={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Select
                    sx={{ width: '10rem' }}
                    size="small"
                    value={selectNextMonth}
                    onChange={(e) => {
                      setSelectMonth(
                        dateFusFormat(
                          subMonths(new Date(`${e.target.value}/01`), 1),
                          'yyyy/MM'
                        )
                      );
                      setSelectNextMonth(e.target.value);
                    }}
                  >
                    {nextMonthList.map((month) => (
                      <MenuItem key={month} value={month}>
                        {dateFusFormat(new Date(`${month}/01`), 'yyyy年MM月')}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="start"
                >
                  <ChangeButton
                    variant="outlined"
                    size="small"
                    disabled={nextMonthList.slice(-1)[0] === selectNextMonth}
                    onClick={(e) => {
                      const date = addMonths(new Date(`${selectMonth}/01`), 1);
                      setSelectMonth(dateFusFormat(date, 'yyyy/MM'));
                      setSelectNextMonth(
                        dateFusFormat(addMonths(date, 1), 'yyyy/MM')
                      );
                    }}
                  >
                    <ArrowForwardIos fontSize="small" color="action" />
                  </ChangeButton>
                </Grid>
              </Grid>
              <ProductCalendar
                month={selectNextMonth}
                startDate={startDate}
                endDate={endDate}
                value={value}
                onChange={onChange}
              />
            </ColBox>
          </RowBox>
          <RowBox gap={1} p={3} bgcolor={theme.palette.grey[100]}>
            {value?.length ? (
              <>
                <Typography>選択中:</Typography>
                <Typography
                  textOverflow={isDaysViewAll ? undefined : 'ellipsis'}
                  overflow={isDaysViewAll ? undefined : 'hidden'}
                  whiteSpace={isDaysViewAll ? undefined : 'nowrap'}
                  width="40rem"
                >
                  {value
                    ?.map((d) =>
                      dateFusFormat(new Date(d), 'M/d(eee)', {
                        locale: dateFnsJs,
                      })
                    )
                    .join('、')}
                </Typography>
                <Typography
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  color={theme.palette.grey[500]}
                  tabIndex={0}
                  onClick={() => {
                    setIsDaysViewAll(!isDaysViewAll);
                  }}
                >
                  {isDaysViewAll ? '省略する' : 'すべてみる'}
                </Typography>
              </>
            ) : (
              '　'
            )}
          </RowBox>
          <Divider />
          <RowBox
            alignItems="center"
            gap={2}
            p={3}
            bgcolor={theme.palette.grey[100]}
          >
            <Typography color={theme.palette.grey[500]}>
              {value?.length ?? 0}件選択中です。
            </Typography>
            <DateRepeatSetting
              ref={ref}
              startDate={startDate}
              endDate={endDate}
              value={value}
              onChange={onChange}
            />
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '5rem' }}
              onClick={() => onChange?.([])}
            >
              クリア
            </Button>
          </RowBox>
        </ColBox>
      </Paper>
      <FormHelperText error={error} sx={{ ml: 2 }}>
        {helperText}
      </FormHelperText>
    </ColBox>
  );
});
